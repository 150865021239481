/** @format */
import { computed } from '@vue/composition-api'
import Store from '@/store'

export default function usePass() {
	const adminPassObj = computed(() => {
		return Store.getters.getAdminPassKey
	})
	const privilegePassObj = computed(() => {
		return Store.getters.getPrivilegePassKey
	})
	const isPassValid = (pass) => {
		const currentTimeInMs = Date.now()
		let validUntil =
			privilegePassObj.value.valid_minutes * -1 * 60000 + currentTimeInMs
		// ? ----------------------------------------------------------------------------------------------
		const createdTime = privilegePassObj.value.created_at
		// ? ----------------------------------------------------------------------------------------------
		if (pass === adminPassObj.value.admin_pass_key) {
			return true
		} else if (
			pass === privilegePassObj.value.privilege_pass_key &&
			validUntil < createdTime
		) {
			return true
		} else {
			return false
		}
	}
	// todo ----------------------------------------------------------------------------------
	return {
		isPassValid,
	}
}
