<!-- @format -->

<template>
	<div>
		<div class="margin-top-30 margin-bottom-30">
			{{ ourCompanyName }}
		</div>
		<div>List - {{ selectedPriceList }}</div>
		<div>
			<b-button
				class="is-info margin-top-15"
				@click="openPDF()"
				style="width: 200px"
				><i class="fas fa-search"></i>&nbsp; Preview
			</b-button>
		</div>
		<div>
			<b-button
				class="is-success margin-top-15"
				@click="downloadPDF()"
				style="width: 200px"
				><i class="fas fa-download"></i>&nbsp; Download</b-button
			>
		</div>
		<div>
			<b-button
				class="is-danger margin-top-15"
				@click="printPDF()"
				style="width: 200px"
				><i class="fas fa-print" style="text-align: left"></i>&nbsp;
				Print</b-button
			>
		</div>
		<div>
			<b-button
				class="is-primary margin-top-15"
				@click="emailPDF()"
				style="width: 200px"
				><i class="fas fa-envelope-square"></i>&nbsp; Email</b-button
			>
		</div>
	</div>
</template>

<script>
import { onMounted, computed, watchEffect } from '@vue/composition-api'
import usePriceListReportData from '@/_srcv2/pages/_utilities/price-list/scripts/PriceListGeneralReport.js'
import usePriceListReportGeneralState from '@/_srcv2/pages/_utilities/price-list/scripts/usePriceListReportGeneralState.js'

// import Store from '@/store'
import axios from 'axios'

export default {
	name: 'ProcessReportPriceList',
	setup() {
		// todo -------------------------------------------------------------
		onMounted(() => {
			init()
		})
		// ----------------------------------------------------------------------------
		const { getPriceListReportData } = usePriceListReportData()
		const {
			priceListReportData,
			ourCompanyEmail,
			ourCompanyTel,
			customerEmail,
			ourCompanyName,
			selectedPriceList,
			keyProcessReport,
		} = usePriceListReportGeneralState()

		watchEffect(() => console.log('keyProcessReport', keyProcessReport.value))

		const dd = getPriceListReportData()
		const getData = () => {
			return new Promise((resolve, reject) => {
				if (dd !== undefined || null) {
					resolve(dd)
				} else {
					const errorObject = {
						msg: "Data couldn't be fetched from store",
					}
					reject(errorObject)
				}
			})
		}
		//  todo ----------------------------------------------------------------------------------------
		const createReport = (dd) => {
			return new Promise((resolve, reject) => {
				if (dd !== undefined || null) {
					const pdfMake = require('pdfmake/build/pdfmake.js')
					const pdfFonts = require('pdfmake/build/vfs_fonts.js')
					pdfMake.vfs = pdfFonts.pdfMake.vfs
					const pdf = pdfMake.createPdf(dd)
					resolve(pdf)
				} else {
					const errorObject = {
						msg: "The report couldn't be created",
					}
					reject(errorObject)
				}
			})
		}
		// todo --------------------------------------------------------------
		// ** Get file name
		const fileName = String('Prislista' + '-' + selectedPriceList.value)
			.trim()
			.replaceAll(' ', '')
			.toLowerCase()
		// ! --------------------------------------------------------------
		const openPDF = () => {
			getData()
				.then((data) => createReport(data))
				.then((pdf) => pdf.open())
		}
		// ! --------------------------------------------------------------
		const downloadPDF = () => {
			getData()
				.then((data) => createReport(data))
				.then((pdf) => pdf.download(`${fileName}.pdf`))
		}
		// ! --------------------------------------------------------------
		const printPDF = () => {
			getData()
				.then((data) => createReport(data))
				.then((pdf) => pdf.print())
		}
		// ! --------------------------------------------------------------
		// todo: Create stream and send it to backend

		// ** define formData
		let formData = new FormData()
		// ** create stream-------------------------------------------------
		const createStream = (dd) => {
			return new Promise((resolve, reject) => {
				if (dd !== undefined || null) {
					const pdfMake = require('pdfmake/build/pdfmake.js')
					const pdfFonts = require('pdfmake/build/vfs_fonts.js')
					pdfMake.vfs = pdfFonts.pdfMake.vfs
					setTimeout(() => {
						const pdfDocGenerator = pdfMake.createPdf(dd)
						pdfDocGenerator.getBlob((blob) => {
							formData.set('fileName', fileName)
							formData.set('pdfFileStream', blob, 'blob.pdf')
							console.log('**** after append blob', ...formData.entries())
						})
					}, 1000)
					resolve(formData)
				} else {
					const errorObject = {
						msg: "The stream couldn't be created",
					}
					reject(errorObject)
				}
			})
		}
		// * -----------------------------------------------------------
		// ** init function
		const init = () => {
			for (const key of formData.keys()) {
				formData.delete(key)
			}
			// todo ---------------------------------------------------)
			const reportData = computed(() => priceListReportData.value) // **************
			console.log('reportData.value', reportData.value)
			formData.delete('pdfFileStream')
			formData.set('ourMail', `Kontakt: ${ourCompanyEmail.value}`)
			formData.set('ourCompany', ourCompanyName.value)
			formData.set('customerMail', customerEmail.value)
			formData.set('reference', 'Kundtjänst')
			formData.set('subject', 'Pris Lista')
			formData.set('tel', ourCompanyTel.value)
			formData.set('message', 'Ni kan kontakta med oss via epost')
			createStream(dd).then((formData) => {
				console.log('****from onMounted form data', ...formData.entries())
				console.log('createStream is fired')
			})
			// console.log('****from onMounted form data', ...formData.entries())
		}
		// todo end -------------------------------------------------------
		const emailPDF = () =>
			getData().then((dd) => {
				createStream(dd).then((formData) => {
					// console.log('stream', formData.files.pdfFileStream[0])
					axios({
						method: 'post',
						url:
							process.env.VUE_APP_MAIL_SERVER_ENVIRONMENT === 'local'
								? process.env.VUE_APP_MAIL_SERVER_DEVELOPMENT_URL
								: process.env.VUE_APP_MAIL_SERVER_URL,
						data: formData,
					})
						.then(function (response) {
							//handle success
							alert(response.data.status)
						})
						.catch(function (err) {
							//handle error
							alert(err)
						})
				})
			})

		// ----------------------------------------------------------------------------------------
		// const {
		// 	reportDocumentType,
		// 	reportCustomer,
		// 	reportDocumentDate,
		// 	reportDocumentStatus,
		// 	isSelectedDocumentReported,
		// 	isUnlockButtonVisible,
		// } = useProcessReportStore()
		// -----------------------------------------------------------------------------------------
		return {
			openPDF,
			downloadPDF,
			printPDF,
			emailPDF,
			fileName,
			selectedPriceList,
			ourCompanyName,
			// reportCustomer,
			// reportDocumentDate,
			// reportDocumentStatus,
			// isSelectedDocumentReported,
			// reportDocumentType,
			// isUnlockButtonVisible,
		}
	},
}
</script>

<style scoped>
.big-font {
	font-weight: bolder;
	font-size: 2em;
}
.sendmail {
	background-color: red;
}
.shake {
	background-color: red;
	animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
	transform: translate3d(0, 0, 0);
}

@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}
</style>
