<!-- @format -->

<template>
	<div>
		<div
			style="
				background-color: rgb(76, 92, 122);
				padding: 30px;
				margin-bottom: -30px;
			"
		>
			<div>
				<div
					style="
						margin-bottom: 20px;
						color: white;
						font-size: x-large;
						font-weight: 500;
					"
				>
					Selected Price Class is {{ selectedPriceList.toUpperCase() }}
				</div>
			</div>
			<b-dropdown :triggers="['hover']" aria-role="list">
				<template #trigger>
					<b-button
						label="Select Price Class!"
						type="is-success"
						icon-right="menu-down"
						style="width: 250px"
					/>
				</template>

				<b-dropdown-item
					aria-role="listitem"
					class="menu-item"
					@click="createPriceList('n')"
					>Price List N</b-dropdown-item
				>
				<hr class="dropdown-divider separator" aria-role="menuitem" />
				<b-dropdown-item
					aria-role="listitem"
					class="menu-item pizzeria"
					@click="createPriceList('a')"
					>Price List A</b-dropdown-item
				>
				<b-dropdown-item
					aria-role="listitem"
					class="menu-item pizzeria"
					@click="createPriceList('b')"
					>Price List B</b-dropdown-item
				>
				<b-dropdown-item
					aria-role="listitem"
					class="menu-item pizzeria"
					@click="createPriceList('c')"
					>Price List C</b-dropdown-item
				>
				<b-dropdown-item
					aria-role="listitem"
					class="menu-item pizzeria"
					@click="createPriceList('d')"
					>Price List D</b-dropdown-item
				>
				<b-dropdown-item
					aria-role="listitem"
					class="menu-item pizzeria"
					@click="createPriceList('s')"
					>Price List S</b-dropdown-item
				>
				<b-dropdown-item
					aria-role="listitem"
					class="menu-item pizzeria"
					@click="createPriceList(1)"
					>ALL PIZZERIA PRICES</b-dropdown-item
				>
				<hr class="dropdown-divider separator" aria-role="menuitem" />
				<b-dropdown-item
					aria-role="listitem"
					class="menu-item hr"
					@click="createPriceList('hra')"
					>Price List HRA</b-dropdown-item
				>
				<b-dropdown-item
					aria-role="listitem"
					class="menu-item hr"
					@click="createPriceList('hrb')"
					>Price List HRB</b-dropdown-item
				>
				<b-dropdown-item
					aria-role="listitem"
					class="menu-item hr"
					@click="createPriceList('hrc')"
					>Price List HRC</b-dropdown-item
				>
				<b-dropdown-item
					aria-role="listitem"
					class="menu-item hr"
					@click="createPriceList('hrd')"
					>Price List HRD</b-dropdown-item
				>
				<b-dropdown-item
					aria-role="listitem"
					class="menu-item hr"
					@click="createPriceList(2)"
					>ALL HR PRICES</b-dropdown-item
				>
				<hr class="dropdown-divider separator" aria-role="menuitem" />
				<b-dropdown-item
					aria-role="listitem"
					class="menu-item z"
					@click="createPriceList('z')"
					>Price List Z</b-dropdown-item
				>
			</b-dropdown>
			<b-dropdown :triggers="['hover']" aria-role="list" style="float: right">
				<template #trigger>
					<b-button
						label="Enter Customer Details!"
						type="is-danger"
						icon-right="menu-down"
						style="width: 250px"
					/>
				</template>
				<b-dropdown-item
					aria-role="listitem"
					class="menu-item"
					@click="createPriceList('a')"
					>Select from our customers</b-dropdown-item
				>
				<b-dropdown-item
					aria-role="listitem"
					class="menu-item"
					@click="createPriceList('b')"
					:disabled="isDisabled"
					>Select from potential customers</b-dropdown-item
				>
				<b-dropdown-item
					aria-role="listitem"
					class="menu-item"
					@click="createPriceList('a')"
					>Enter Customer Address Manually</b-dropdown-item
				>
			</b-dropdown>
			<div v-if="creatingTable" class="margin-top-30 margin-bottom-30">
				<div class="loader"></div>
			</div>
		</div>
	</div>
</template>

<script>
import usePriceListReportGeneralState from '@/_srcv2/pages/_utilities/price-list/scripts/usePriceListReportGeneralState.js'
import { ref } from '@vue/composition-api'
import numeral from 'numeral'

export default {
	name: 'SelectPriceListClass',
	setup() {
		const isDisabled = ref(true)
		// todo ----------------------------------------------------
		const {
			priceListReportData,
			listArr,
			stockListSelectedPrice,
			showPriceListTablePreview,
			KeyPriceListTablePreview,
			priceClass,
			selectedPriceList,
		} = usePriceListReportGeneralState()
		// todo ---------------------------------------------------
		const formatNumber = (number) => {
			return numeral(number / 100).format('0,0.00')
		}
		const formatNumberWithCurrency = (number, currency = 'kr.') => {
			return numeral(number / 100).format('0,0.00') + ' ' + currency
		}
		const creatingTable = ref(false)
		const createPriceList = (selectedList) => {
			console.log('createPriceList is fired')
			showPriceListTablePreview.value = false
			creatingTable.value = true
			if (selectedList === 1) {
				priceClass.value = 1
				selectedPriceList.value = 'P All Prices'
				listArr.value = priceListReportData.value.map((item) => {
					return {
						stock_id: item.stock_id,
						stock_name: item.stock_name + ' - ' + item.stock_unit,
						stock_group: item.stock_group,
						stock_price_n: formatNumber(item.stock_price),
						stock_price_z: formatNumber(item.stock_price_z),
						stock_price_a: formatNumber(item.stock_price_a),
						stock_price_b: formatNumber(item.stock_price_b),
						stock_price_c: formatNumber(item.stock_price_c),
						stock_price_d: formatNumber(item.stock_price_d),
						stock_price_s: formatNumber(item.stock_price_s),
					}
				})
			} else if (selectedList === 2) {
				priceClass.value = 2
				selectedPriceList.value = 'HR All Prices'
				listArr.value = priceListReportData.value.map((item) => {
					return {
						stock_id: item.stock_id,
						stock_name: item.stock_name + ' - ' + item.stock_unit,
						stock_group: item.stock_group,
						stock_price_n: formatNumber(item.stock_price),
						stock_price_z: formatNumber(item.stock_price_z),
						stock_price_hra: formatNumber(item.stock_price_hra),
						stock_price_hrb: formatNumber(item.stock_price_hrb),
						stock_price_hrc: formatNumber(item.stock_price_hrc),
						stock_price_hrd: formatNumber(item.stock_price_hrd),
					}
				})
			} else {
				listArr.value = priceListReportData.value.map((item) => {
					switch (selectedList) {
						case 'n':
							stockListSelectedPrice.value = formatNumberWithCurrency(
								item.stock_price,
							)
							priceClass.value = 0
							selectedPriceList.value = 'N'
							break
						case 'a':
							stockListSelectedPrice.value = formatNumberWithCurrency(
								item.stock_price_a,
							)
							priceClass.value = 0
							selectedPriceList.value = 'A'
							break
						case 'b':
							stockListSelectedPrice.value = formatNumberWithCurrency(
								item.stock_price_b,
							)
							priceClass.value = 0
							selectedPriceList.value = 'B'
							break
						case 'c':
							stockListSelectedPrice.value = formatNumberWithCurrency(
								item.stock_price_c,
							)
							priceClass.value = 0
							selectedPriceList.value = 'C'
							break
						case 'd':
							stockListSelectedPrice.value = formatNumberWithCurrency(
								item.stock_price_d,
							)
							priceClass.value = 0
							selectedPriceList.value = 'D'
							break
						case 's':
							stockListSelectedPrice.value = formatNumberWithCurrency(
								item.stock_price_s,
							)
							priceClass.value = 0
							selectedPriceList.value = 'S'
							break
						case 'hra':
							stockListSelectedPrice.value = formatNumberWithCurrency(
								item.stock_price_hra,
							)
							priceClass.value = 0
							selectedPriceList.value = 'HRA'
							break
						case 'hrb':
							stockListSelectedPrice.value = formatNumberWithCurrency(
								item.stock_price_hrb,
							)
							priceClass.value = 0
							selectedPriceList.value = 'HRB'
							break
						case 'hrc':
							stockListSelectedPrice.value = formatNumberWithCurrency(
								item.stock_price_hrc,
							)
							priceClass.value = 0
							selectedPriceList.value = 'HRC'
							break
						case 'hrd':
							stockListSelectedPrice.value = formatNumberWithCurrency(
								item.stock_price_hrd,
							)
							priceClass.value = 0
							selectedPriceList.value = 'HRD'
							break
						case 'z':
							stockListSelectedPrice.value = formatNumberWithCurrency(
								item.stock_price_z,
							)
							priceClass.value = 0
							selectedPriceList.value = 'Z'
							break
						default:
							stockListSelectedPrice.value = formatNumberWithCurrency(
								item.stock_price,
							)
							priceClass.value = 1
							selectedPriceList.value = 'N'
					}
					return {
						stock_id: item.stock_id,
						stock_name: item.stock_name,
						stock_unit: item.stock_unit,
						stock_price: stockListSelectedPrice.value,
						stock_group: item.stock_group,
					}
				})
			}
			console.log('listArr.value', listArr.value)
			console.log('priceClass', priceClass.value)
			KeyPriceListTablePreview.value += 1
			setTimeout(() => {
				creatingTable.value = false
				showPriceListTablePreview.value = true
			}, 1000)
			// showPriceListTablePreview.value = true
		}
		return {
			createPriceList,
			creatingTable,
			isDisabled,
			selectedPriceList,
		}
	},
}
</script>

<style lang="css" scoped>
.menu-item {
	font-size: large;
	font-weight: 700;
}
.pizzeria {
	color: blue;
}
.hr {
	color: red;
}
.z {
	color: darkgreen;
}
/* Large rounded green border */
.separator {
	border: 3px solid green;
	border-radius: 5px;
	margin-left: 10px;
	margin-right: 10px;
}

.loader {
	border: 16px solid #f3f3f3;
	border-radius: 50%;
	border-top: 16px solid #3498db;
	width: 120px;
	height: 120px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
