/** @format */

import { reactive, toRefs } from '@vue/composition-api'
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

const state = reactive({
	showGetPriceListData: true,
	showPriceListClass: false,
	showPriceListTablePreview: false,
	KeyPriceListTablePreview: 1,
	showVPopup: false,
	showVPopupProcessReport: false,
	showVPopupCustomerAddress: false,
	showVPopupSelectCustomer: false,
	formName: 'Edit Stock Item',
	formNameProcessReport: 'Process Report Price List',
	formNameCustomerAddress: 'Potential Customer Address',
	keyProcessReport: 1,
	// ! ------------------------------
	stockGroupsForReport: [],
	priceListReportData: [],
	ourCompanyName: '',
	ourCompanyEmail: '',
	ourCompanyTel: '',
	customerEmail: 'evedat@gmail.com',
	customerName: 'Vedat',
	// ! ------------------------------
	priceClass: 0,
	stockListSelectedPrice: 0,
	listArr: [],
	selectedPriceList: '',
})

export default function usePriceListReportGeneralState() {
	return {
		state,
		...toRefs(state),
	}
}
