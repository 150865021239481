<!-- @format -->

<template>
	<div>
		<div v-if="loading" class="loader"></div>
		<div v-if="!loading">Not Loading...</div>
	</div>
</template>

<script>
import GetPriceListGeneralDataQuery from '@/_srcv2/pages/_utilities/price-list/graphql/getPriceListGeneralData.query.graphql'
import { reactive } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
import Store from '@/store'
import usePriceListReportGeneralState from '@/_srcv2/pages/_utilities/price-list/scripts/usePriceListReportGeneralState.js'

export default {
	name: 'GetPriceListData',

	setup() {
		// todo get price list report info
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { onResult, loading } = useQuery(
			GetPriceListGeneralDataQuery,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		// todo ----------------------------------------------------
		const {
			showGetPriceListData,
			showPriceListClass,
			stockGroupsForReport,
			priceListReportData,
			ourCompanyName,
			ourCompanyEmail,
			ourCompanyTel,
		} = usePriceListReportGeneralState()
		// todo ---------------------------------------------------
		const storeData = (result) => {
			return new Promise((resolve, reject) => {
				if (result) {
					stockGroupsForReport.value = result.data._type_stock_groups
					priceListReportData.value = result.data.stock
					ourCompanyName.value = result.data.companies.company_name
					ourCompanyEmail.value = result.data.companies.company_email
					ourCompanyTel.value = result.data.companies.company_tel
					resolve(true)
				} else {
					const error = new Error('There is not any result from the query')
					reject(error)
				}
			}).catch((err) => console.log('Error: ', err))
		}
		// todo ----------------------------------------------------
		onResult((result) => {
			storeData(result).then(() => {
				showGetPriceListData.value = false
				showPriceListClass.value = true
			})
		})

		// todo -----------------------------------------------------------------
		return {
			loading,
		}
	},
}
</script>

<style lang="css" scoped>
.loader {
	border: 16px solid #f3f3f3;
	border-radius: 50%;
	border-top: 16px solid #3498db;
	width: 120px;
	height: 120px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
