<!-- @format -->

<template>
	<layout-wide>
		<div v-if="$store.getters.getShowGetPassDataComponent">
			<GetPassDataComponent />
		</div>
		<div v-if="!hasPermission && !$store.getters.getShowGetPassDataComponent">
			<b-button class="is-success" @click="unlockEditPrice">
				Get Permission</b-button
			>
		</div>
		<div v-if="hasPermission">
			<sub-navbar page="Utilities" />
			<section class="hero is-success margin-bottom-20 margin-top-20">
				<div class="hero-body">
					<p class="title">Stock Items PriceList</p>
					<p class="subtitle">
						Please Select a price class to preview price list!
					</p>
				</div>
			</section>
			<div v-if="showGetPriceListData"><GetPriceListData /></div>
			<div v-if="showPriceListClass"><SelectPriceListClass /></div>
			<div v-if="showPriceListTablePreview" :key="KeyPriceListTablePreview">
				<PriceListTablePreview :refreshData="setInitialState" />
			</div>
			<div v-if="showVPopup">
				<VPopup :closePopup="onClosePopup">
					<template #header>
						<div>
							{{ formName }}
						</div>
					</template>
					<template #body>
						<div>
							<EditStockItem />
						</div>
					</template>
					<template #footer>
						<div>
							{{ formName }}
						</div>
					</template>
				</VPopup>
			</div>
		</div>
		<div v-if="showVPopupProcessReport">
			<VPopup :closePopup="onClosePopupProcessReport">
				<template #header>
					<div>
						{{ formNameProcessReport }}
					</div>
				</template>
				<template #body>
					<div>
						<ProcessReportPriceList :key="keyProcessReport" />
					</div>
				</template>
				<template #footer>
					<div>
						{{ formNameProcessReport }}
					</div>
				</template>
			</VPopup>
		</div>
	</layout-wide>
</template>
<script>
import GetPassDataComponent from '@/_srcv2/composables/admin/GetPassDataComponent.vue'
import GetPriceListData from './components/GetPriceListData.vue'
import SelectPriceListClass from './components/SelectPriceListClass.vue'
import PriceListTablePreview from './components/PriceListTablePreview.vue'
import { onMounted, ref } from '@vue/composition-api'
import VPopup from '@/_srcv2/pages/admin/edit-stock-prices/sub-components/VPopup.vue'
import usePriceListReportGeneralState from '@/_srcv2/pages/_utilities/price-list/scripts/usePriceListReportGeneralState.js'
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'
import EditStockItem from '@/_srcv2/pages/stock-ours/edit-view-stock-item/EditStockItem.vue'
import ProcessReportPriceList from '@/_srcv2/pages/_utilities/price-list/components/ProcessReportPriceList.vue'
import usePass from '@/_srcv2/composables/admin/usePass.js'
import Store from '@/store'

export default {
	name: 'PriceListGeneral',
	components: {
		SubNavbar,
		GetPriceListData,
		SelectPriceListClass,
		PriceListTablePreview,
		EditStockItem,
		ProcessReportPriceList,
		VPopup,
		GetPassDataComponent,
	},
	setup() {
		const {
			showGetPriceListData,
			showPriceListClass,
			showPriceListTablePreview,
			KeyPriceListTablePreview,
			showVPopup,
			showVPopupProcessReport,
			formName,
			formNameProcessReport,
			selectedPriceList,
			keyProcessReport,
		} = usePriceListReportGeneralState()

		const hasPermission = ref(false)
		const setInitialState = () => {
			showGetPriceListData.value = true
			showPriceListClass.value = false
			showPriceListTablePreview.value = false
			showVPopup.value = false
			selectedPriceList.value = 'n'
		}
		// todo -------------------------------------------------------------------------
		const { isPassValid } = usePass()
		const unlockEditPrice = () => {
			let pwd = prompt('Type password to create a list')
			console.log('isPassValid(pwd)', isPassValid(pwd))
			if (isPassValid(pwd)) {
				hasPermission.value = true
			} else
				alert(`Password does not match
  You don't have permission
  to create price list
  contact with administrator`)
		}
		onMounted(() => {
			setInitialState()
			Store.dispatch('setShowGetPassDataComponent', true)
		})
		const onClosePopup = () => {
			showVPopup.value = false
		}
		const onClosePopupProcessReport = () => {
			showVPopupProcessReport.value = false
		}
		return {
			hasPermission,
			showGetPriceListData,
			showPriceListClass,
			showPriceListTablePreview,
			KeyPriceListTablePreview,
			showVPopup,
			showVPopupProcessReport,
			setInitialState,
			onClosePopup,
			onClosePopupProcessReport,
			formName,
			formNameProcessReport,
			keyProcessReport,
			unlockEditPrice,
		}
	},
}
</script>

<style lang="scss" scoped></style>
