<!-- @format -->

<template>
	<div>
		<div class="margin-top-30" v-if="priceClass === 0">
			<div class="margin-top-30">
				<vue-good-table
					:columns="columns"
					:rows="listArr"
					styleClass="vgt-table striped bordered"
					theme="black-rhino"
					:fixed-header="true"
					@on-cell-click="onCellClick"
					:search-options="{
						enabled: true,
					}"
					:sort-options="sortOptions"
					:pagination-options="{
						enabled: true,
						mode: 'records',
						perPage: 10,
						position: 'bottom',
						perPageDropdown: [10, 15, 20, 100],
						dropdownAllowAll: false,
						setCurrentPage: 1,
						jumpFirstOrLast: true,
						firstLabel: 'First',
						lastLabel: 'Last',
						nextLabel: 'next',
						prevLabel: 'prev',
						rowsPerPageLabel: 'Rows per page',
						ofLabel: 'of',
						pageLabel: 'page', // for 'pages' mode
						allLabel: 'All',
					}"
				>
					<template slot="table-row" slot-scope="props">
						<span v-if="props.column.field === 'select'">
							<b-button class="is-small is-success">Select</b-button>
						</span>
						<span v-else-if="props.column.field === 'stock_price'">
							<p class="text-bold text-align-right text-blue">
								{{ props.row.stock_price }}
							</p>
						</span>
						<span v-else-if="props.column.field === 'stock_unit'">
							<p class="text-align-center">
								{{ props.formattedRow[props.column.field] }}
							</p>
						</span>
						<span v-else-if="props.column.field === 'stock_id'">
							<p class="text-bold text-green text-align-center">
								{{ props.formattedRow[props.column.field] }}
							</p>
						</span>
						<span v-else>
							{{ props.formattedRow[props.column.field] }}
						</span>
					</template>
				</vue-good-table>
			</div>
		</div>
		<div class="margin-top-30" v-if="priceClass === 1">
			<div class="margin-bottom-30 margin-top-30">
				<vue-good-table
					:columns="columnsAllPizzeria"
					:rows="listArr"
					styleClass="vgt-table striped bordered"
					theme="black-rhino"
					:fixed-header="true"
					@on-cell-click="onCellClick"
					:search-options="{
						enabled: true,
					}"
					:sort-options="sortOptions"
					:pagination-options="{
						enabled: true,
						mode: 'records',
						perPage: 10,
						position: 'bottom',
						perPageDropdown: [10, 15, 20, 100],
						dropdownAllowAll: false,
						setCurrentPage: 1,
						jumpFirstOrLast: true,
						firstLabel: 'First',
						lastLabel: 'Last',
						nextLabel: 'next',
						prevLabel: 'prev',
						rowsPerPageLabel: 'Rows per page',
						ofLabel: 'of',
						pageLabel: 'page', // for 'pages' mode
						allLabel: 'All',
					}"
				>
					<template slot="table-row" slot-scope="props">
						<span v-if="props.column.field === 'select'">
							<b-button class="is-small is-success">Select</b-button>
						</span>
						<span v-else-if="props.column.field.match(/stock_price.*/)">
							<p class="text-bold" style="text-align: center">
								{{ props.formattedRow[props.column.field] }}
							</p>
						</span>
						<span v-else>
							{{ props.formattedRow[props.column.field] }}
						</span>
					</template>
				</vue-good-table>
			</div>
		</div>
		<div class="margin-top-30" v-if="priceClass === 2">
			<div class="margin-bottom-30 margin-top-30">
				<vue-good-table
					:columns="columnsAllHR"
					:rows="listArr"
					styleClass="vgt-table striped bordered"
					theme="black-rhino"
					:fixed-header="true"
					@on-cell-click="onCellClick"
					:search-options="{
						enabled: true,
					}"
					:sort-options="sortOptions"
					:pagination-options="{
						enabled: true,
						mode: 'records',
						perPage: 10,
						position: 'bottom',
						perPageDropdown: [10, 15, 20, 100],
						dropdownAllowAll: false,
						setCurrentPage: 1,
						jumpFirstOrLast: true,
						firstLabel: 'First',
						lastLabel: 'Last',
						nextLabel: 'next',
						prevLabel: 'prev',
						rowsPerPageLabel: 'Rows per page',
						ofLabel: 'of',
						pageLabel: 'page', // for 'pages' mode
						allLabel: 'All',
					}"
				>
					<template slot="table-row" slot-scope="props">
						<span v-if="props.column.field === 'select'">
							<b-button class="is-small is-success">Select</b-button>
						</span>
						<span v-else-if="props.column.field.match(/stock_price.*/)">
							<p class="text-bold text-align-right">
								{{ props.formattedRow[props.column.field] }}
							</p>
						</span>
						<span v-else>
							{{ props.formattedRow[props.column.field] }}
						</span>
					</template>
				</vue-good-table>
			</div>
		</div>
		<div
			style="
				background-color: rgb(76, 92, 122);
				padding-bottom: 30px;
				display: flex;
				justify-content: space-between;
			"
		>
			<div>
				<b-button
					class="margin-top-30 margin-left-15 width-250"
					style="background-color: rgb(182, 90, 92); color: white"
					@click="setInitialState"
					>Refresh Data</b-button
				>
				<b-button
					class="margin-top-30 margin-left-15 width-250"
					style="background-color: rgb(61, 196, 134); color: white"
					:disabled="pdfDisabled"
					@click="getPdfReport"
				>
					Get Pdf Report</b-button
				>
			</div>
			<div>
				<download-csv :data="listArr">
					<div class="margin-top-30 width-250" style="margin-right: 15px">
						<b-button type="is-danger" outlined>
							Download the Price List
						</b-button>
					</div>
				</download-csv>
			</div>
		</div>
		<div style="margin-top: 40px"></div>
	</div>
</template>

<script>
import usePriceListReportGeneralState from '@/_srcv2/pages/_utilities/price-list/scripts/usePriceListReportGeneralState.js'
import { useQuery } from '@vue/apollo-composable'
import { ref, computed } from '@vue/composition-api'
import Store from '@/store'
import GetStockItemQuery from '@/_srcv2/pages/stock-ours/graphql/GetStockItemQuery.graphql'
import stockItemStore from '@/_srcv2/pages/stock-ours/store-stock/useStockItemState.js'
import useReportNumber from '@/_srcv2/views/_shared/_composables/format-report-numbers/useReportNumber.js'

export default {
	name: 'PriceListTablePreview',
	props: {
		refreshData: {
			type: Function,
			default: () => {},
		},
	},
	setup(props) {
		const setInitialState = ref(props.refreshData)
		const {
			priceClass,
			listArr,
			selectedPriceList,
			showVPopup,
			showVPopupProcessReport,
			keyProcessReport,
		} = usePriceListReportGeneralState()
		const pdfDisabled = computed(() => priceClass.value !== 0)
		const columns = [
			{
				label: 'Select',
				field: 'select',
				width: '85px',
				sortable: false,
			},
			{
				field: 'stock_id',
				label: 'Stock Number',
				width: '190',
			},
			{
				field: 'stock_name',
				label: 'Namn',
				width: '300',
			},
			{
				field: 'stock_unit',
				label: 'Enhet',
				width: '80',
			},
			{
				field: 'stock_price',
				label: 'Pris',
				numeric: true,
				width: '120',
			},
		]
		// todo ----------------------------------------------------------------------------
		const columnsAllPizzeria = [
			{
				label: 'Select',
				field: 'select',
				width: '75px',
				sortable: false,
			},
			{
				field: 'stock_id',
				label: 'Stock Number',
				width: '182',
			},
			{
				field: 'stock_name',
				label: 'Namn - Enhet',
				width: '290',
			},
			{
				field: 'stock_price_n',
				label: 'P.N',
				numeric: true,
				width: '115',
			},
			{
				field: 'stock_price_z',
				label: 'P.Z',
				numeric: true,
				width: '115',
			},
			{
				field: 'stock_price_a',
				label: 'P.A',
				numeric: true,
				width: '115',
			},
			{
				field: 'stock_price_b',
				label: 'P.B',
				numeric: true,
				width: '115',
			},
			{
				field: 'stock_price_c',
				label: 'P.C',
				numeric: true,
				width: '115',
			},
			{
				field: 'stock_price_d',
				label: 'P.D',
				numeric: true,
				width: '115',
			},
			{
				field: 'stock_price_s',
				label: 'P.S',
				numeric: true,
				width: '115',
			},
		]
		// todo ----------------------------------------------------------------------------
		const columnsAllHR = [
			{
				label: 'Select',
				field: 'select',
				width: '85px',
				sortable: false,
			},
			{
				field: 'stock_id',
				label: 'Stock Number.',
				width: '180',
			},
			{
				field: 'stock_name',
				label: 'Namn - Enhet',
				width: '290',
			},
			{
				field: 'stock_price_n',
				label: 'P.N',
				numeric: true,
				width: '115',
			},
			{
				field: 'stock_price_z',
				label: 'P.Z',
				numeric: true,
				width: '115',
			},
			{
				field: 'stock_price_hra',
				label: 'P.HRA',
				numeric: true,
				width: '115',
			},
			{
				field: 'stock_price_hrb',
				label: 'P.HRB',
				numeric: true,
				width: '115',
			},
			{
				field: 'stock_price_hrc',
				label: 'P.HRC',
				numeric: true,
				width: '115',
			},
			{
				field: 'stock_price_hrd',
				label: 'P.HRD',
				numeric: true,
				width: '115',
			},
		]
		// todo ---------------------------------------------------------------
		const selectedStockItemId = ref('')
		const { refetch: refetchStockItem, onResult: onResultStockItem } = useQuery(
			GetStockItemQuery,
			() => ({
				stock_id: selectedStockItemId.value,
				company_id: Store.getters.getUserCurrentCompany,
			}),
			() => ({
				fetchPolicy: 'no-cache',
				// enabled: queryEnabled.value,
			}),
		)
		const showEditForm = () => {
			showVPopup.value = true
			// popupType.value = 'edit'
		}
		onResultStockItem((result) => {
			console.log('*** onResult is fired', result.data)
			if (isConfirmationShown.value === true) {
				console.log(
					'onResultStockItem isConfirmationShown',
					isConfirmationShown.value,
				)
				setFormFieldsValues(result).then(() => {
					showEditForm()
				})
			} else {
				console.log('else is fired')
			}
		})
		// todo ---------------------------------------------------------------
		const isConfirmationShown = ref(false)
		const showConfirmation = () => {
			if (
				confirm(
					'Are you sure to edit the stock item \n To cancel edit press "ok" \n To return back press "cancel"',
				)
			) {
				refetchStockItem()
			} else {
				isConfirmationShown.value = false
			}
		}
		// todo --------------------------------------------------------------------
		const setVariablesQuery = (item) => {
			if (item !== null || undefined) {
				selectedStockItemId.value = item.stock_id
				isConfirmationShown.value = true
				showConfirmation()
			}
		}
		const { modelStock } = stockItemStore()
		const { intToFloatCleave } = useReportNumber()
		const setFormFieldsValues = (result) => {
			console.log('------- stockItem', result)
			return new Promise((resolve, reject) => {
				if (result !== null || undefined) {
					const item = result.data.stock[0]
					console.log('*** item', item)
					const campaignPrice = intToFloatCleave(item.campaign_price)
					modelStock.stockId = item.stock_id
					modelStock.stockName = item.stock_name
					modelStock.stockUnit = item.stock_unit
					modelStock.stockPrice = intToFloatCleave(item.stock_price)
					modelStock.stockPriceA = intToFloatCleave(item.stock_price_a)
					modelStock.stockPriceB = intToFloatCleave(item.stock_price_b)
					modelStock.stockPriceC = intToFloatCleave(item.stock_price_c)
					modelStock.stockPriceD = intToFloatCleave(item.stock_price_d)
					modelStock.stockPriceS = intToFloatCleave(item.stock_price_s)
					modelStock.stockPriceZ = intToFloatCleave(item.stock_price_z)
					modelStock.stockPriceHRA = intToFloatCleave(item.stock_price_hra)
					modelStock.stockPriceHRB = intToFloatCleave(item.stock_price_hrb)
					modelStock.stockPriceHRC = intToFloatCleave(item.stock_price_hrc)
					modelStock.stockPriceHRD = intToFloatCleave(item.stock_price_hrd)
					modelStock.stockMoms = item.stock_moms
					modelStock.stockGroup = item.stock_group
					modelStock.stockAlarm = item.stock_alarm
					modelStock.stockShelf = item.stock_shelf
					modelStock.companyId = item.company_id
					modelStock.stockIsActive = item.stock_is_active
					modelStock.campaignPrice =
						// eslint-disable-next-line use-isnan
						campaignPrice == NaN ||
						campaignPrice === undefined ||
						campaignPrice === null ||
						campaignPrice === ''
							? 0
							: campaignPrice
					modelStock.isCampaignActive = false
					resolve('ok')
				} else {
					const reason = new Error('Data could not be set')
					reject(reason)
				}
			})
		}
		// todo ----------------------------------------------------------------
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				console.log('params.row', params.row)
				setVariablesQuery(params.row)
			}
		}
		const onRowDoubleClick = (params) => {
			setVariablesQuery(params.row)
		}

		// todo ----------------------------------------------------------------
		const sortOptions = {
			enabled: true,
			initialSortBy: { field: 'stock_id', type: 'asc' },
		}
		// todo ----------------------------------------------------------------
		const getPdfReport = () => {
			keyProcessReport.value += 1
			showVPopupProcessReport.value = true
		}
		return {
			columns,
			columnsAllPizzeria,
			columnsAllHR,
			priceClass,
			listArr,
			onCellClick,
			onRowDoubleClick,
			sortOptions,
			selectedPriceList,
			getPdfReport,
			pdfDisabled,
			setInitialState,
		}
	},
}
</script>

<style lang="scss" scoped></style>
