/** @format */

// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not sher the state
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)
import numeral from 'numeral'
import { reactive } from '@vue/composition-api'
// *** Get the data from store ***
import Store from '@/store'
import dateFormat, { masks } from 'dateformat'
import usePriceListReportGeneralState from '@/_srcv2/pages/_utilities/price-list/scripts/usePriceListReportGeneralState.js'

const { stockGroupsForReport, priceListReportData, selectedPriceList } =
	usePriceListReportGeneralState()
console.log('*** ===> stockGroupsForReport', stockGroupsForReport.value)
console.log('*** ===> priceListReportData', priceListReportData.value)
const now = new Date()
masks.ve = 'yyyy-mm-dd'

const yearNow = now.getFullYear()
const monthNow = parseInt(now.getMonth())

const issueDate = dateFormat(now, 've')
const getDueDate = (q) => {
	if (q <= 2) {
		return `${yearNow}-03-31`
	} else if (q <= 5) {
		return `${yearNow}-06-30`
	} else if (q <= 8) {
		return `${yearNow}-09-30`
	} else if (q <= 11) {
		return `${yearNow}-12-31`
	}
}
const formatNumberWithCurrency = (number, currency = 'kr.') => {
	return numeral(number / 100).format('0,0.00') + ' ' + currency
}

const getItemListPrice = (item) => {
	switch (selectedPriceList.value) {
		case 'N':
			return formatNumberWithCurrency(item.stock_price_n)
		case 'A':
			return formatNumberWithCurrency(item.stock_price_a)
		case 'B':
			return formatNumberWithCurrency(item.stock_price_b)
		case 'C':
			return formatNumberWithCurrency(item.stock_price_c)
		case 'D':
			return formatNumberWithCurrency(item.stock_price_d)
		case 'S':
			return formatNumberWithCurrency(item.stock_price_s)
		case 'HRA':
			return formatNumberWithCurrency(item.stock_price_hra)
		case 'HRB':
			return formatNumberWithCurrency(item.stock_price_hrb)
		case 'HRC':
			return formatNumberWithCurrency(item.stock_price_hrc)
		case 'HRD':
			return formatNumberWithCurrency(item.stock_price_hrd)
		case 'Z':
			return formatNumberWithCurrency(item.stock_price_z)
		default:
			return formatNumberWithCurrency(item.stock_price_n)
	}
}

// ** ---------------------------------------------------------------------------------------
const getPriceListReportData = () => {
	const documentData = priceListReportData.value
	const documentOurCompany = Store.getters.getOurCompanies
	const relatedStockGroupsArray = stockGroupsForReport.value
	console.log('*** ===> documentData', priceListReportData.value)
	console.log('***documentOurCompany', documentOurCompany)
	console.log('***StockGroupsForReport', stockGroupsForReport.value)
	console.log('***relatedStockGroupsArray', relatedStockGroupsArray)
	console.log('*** ===> *** logo', Store.getters.getLogo)
	const data = {
		footerLeft: 'myLeftFooter',
		logo: Store.getters.getLogo,
		aboutUsImage64: Store.getters.getAboutUs,
		watermark: Store.getters.getWatermark,
		documentType: 'Prislista',
		dateIssued: issueDate,
		dateIssuedLabel: 'Datum',
		dueDate: getDueDate(monthNow),
		dueDateLabel: 'Giltig till',
		stockNumberLabel: 'Stock nummer',
		nameLabel: 'Namn',
		unitLabel: 'Enhet',
		priceLabel: 'Pris',
		notesLabel: 'Anteckningarna',
		notesLine1: `Kundtjänst Tel: ${documentOurCompany.company_tel} Epost: ${documentOurCompany.company_customer_service_email}`,
		notesLine2:
			'Oförväntade producent förändringar kan orsaka prisförändringar innan datumet på prislistan löper ut.',
		notesLine3: 'Moms tillkommer våra priser',
		page: 'sida ',
		of: ' av ',
	}
	const docDefinition = reactive({
		pageSize: 'A4',
		pageOrientation: 'portrait',
		pageMargins: [50, 20, 40, 70],
		footer: function (currentPage, pageCount) {
			return {
				// table: {
				//   widths: ['*'],
				//   body: [[' '], [' ']],
				// },
				// layout: 'lightHorizontalLines',
				columns: [
					{ text: selectedPriceList.value, style: 'documentFooterLeft' },
					{
						text: data.page + currentPage.toString() + data.of + pageCount,
						style: 'documentFooterRight',
					},
				],
			}
		},
		background: [
			{
				image: data.watermark,
				opacity: 0.3,
				alignment: 'center',
				height: 264,
				margin: [0, (842 - 264) / 2, 0, 0],
			},
			{
				table: {
					widths: [522],
					body: [[' '], [' ']],
				},
				layout: 'lightHorizontalLines',
				margin: [50, 220, 50, 0],
			},
			// {
			//   canvas: [
			//     {
			//       type: 'line',
			//       x1: 50,
			//       y1: 195,
			//       x2: 570,
			//       y2: 195,
			//       lineWidth: 1,
			//     },
			//   ],
			// },
		],
		content: [],
		styles: {
			// Document Header
			documentHeaderLeft: {
				fontSize: 10,
				margin: [5, 5, 5, 40],
				alignment: 'left',
			},
			documentHeaderCenter: {
				fontSize: 10,
				margin: [5, 5, 5, 40],
				alignment: 'center',
			},
			documentHeaderRight: {
				fontSize: 10,
				margin: [5, 5, 5, 40],
				alignment: 'right',
			},
			// Document Footer
			documentFooterLeft: {
				fontSize: 8,
				bold: false,
				margin: [50, 30, 5, 40],
				alignment: 'left',
			},
			documentFooterLineLeft: {
				fontSize: 10,
				margin: [50, 0, 5, 0],
				alignment: 'left',
			},
			documentFooterLineCenter: {
				fontSize: 10,
				margin: [0, 0, 5, 0],
				alignment: 'center',
			},
			documentFooterLineRight: {
				fontSize: 10,
				margin: [0, 0, 5, 50],
				alignment: 'right',
			},
			documentFooterRight: {
				fontSize: 10,
				margin: [5, 30, 40, 40],
				alignment: 'right',
			},
			// Document Title
			documentTitle: {
				fontSize: 22,
				bold: true,
				alignment: 'right',
				margin: [0, 0, 0, 15],
			},
			// Document Details
			documentSubTitle: {
				fontSize: 12,
				alignment: 'right',
			},
			documentSubValue: {
				fontSize: 12,
				alignment: 'right',
			},
			// Billing Headers
			documentBillingTitle: {
				fontSize: 12,
				bold: true,
				alignment: 'left',
				margin: [50, 20, 0, 5],
			},
			// Billing Details
			documentBillingCompanyDetails: {
				alignment: 'left',
				fontSize: 11,
				bold: true,
				margin: [50, 0, 0, 0],
			},
			documentBillingDetails: {
				alignment: 'left',
				fontSize: 10,
				margin: [50, 0, 0, 0],
			},
			documentBillingAddressTitle: {
				margin: [50, 2, 0, 1],
				bold: true,
				fontSize: 10,
			},
			documentBillingAddress: {
				fontSize: 10,
				margin: [50, 0, 0, 0],
			},
			organizationNumber: {
				margin: [50, 0, 0, 0],
				bold: true,
				fontSize: 10,
			},
			stockGroup: {
				margin: [0, 10, 0, 10],
				bold: true,
				fontSize: 11,
			},
			// Items Header
			itemsHeader: {
				margin: [0, 5, 0, 5],
				bold: true,
				fontSize: 10,
			},
			// Item Title
			itemTitle: {
				bold: true,
				fontSize: 10,
			},
			itemSubTitle: {
				italics: true,
				fontSize: 10,
			},
			// Items Footer (Subtotal, Total, Tax, etc)
			itemsFooterSubTitle: {
				margin: [0, 5, 0, 0],
				bold: true,
				alignment: 'right',
				fontSize: 10,
			},
			itemsFooterSubValue: {
				margin: [0, 5, 13, 0],
				bold: true,
				alignment: 'right', // center
				fontSize: 10,
			},
			itemsFooterTotalTitle: {
				margin: [0, 5, 0, 0],
				bold: true,
				alignment: 'right',
				fontSize: 10,
			},
			itemsFooterTotalValue: {
				margin: [0, 5, 12, 0], // *******************
				bold: true,
				alignment: 'right',
				fontSize: 10,
			},
			notesTitle: {
				fontSize: 10,
				bold: true,
				margin: [0, -10, 0, 3],
			},
			notesTitleTop: {
				fontSize: 12,
				bold: true,
				margin: [0, 0, 0, 3],
			},
			notesText: {
				fontSize: 10,
			},
			notesTextBold: {
				fontSize: 12,
				bold: true,
			},
			center: {
				alignment: 'center',
			},
			alignRight: {
				alignment: 'right',
			},
			alignLeft: {
				alignment: 'left',
			},
		},
		tableHeader: {
			bold: true,
			alignment: 'center',
			fontSize: 10,
		},
		defaultStyle: {
			columnGap: 20,
		},
	})
	// ********************** PUSH CONTENT BEGIN********************************************************
	const header = {
		columns: [
			{
				image: data.logo,
				width: 150,
			},
			[
				{
					text: data.documentType,
					style: 'documentTitle',
					width: '*',
				},
				{
					stack: [
						{
							columns: [
								{
									text: data.dateIssuedLabel,
									style: 'documentSubTitle',
									width: '*',
								},
								{
									text: data.dateIssued,
									style: 'documentSubValue',
									width: 100,
								},
							],
						},
						{
							columns: [
								{
									text: data.dueDateLabel,
									style: 'documentSubTitle',
									width: '*',
								},
								{
									text: data.dueDate,
									style: 'documentSubValue',
									width: 100,
								},
							],
						},
					],
				},
			],
		],
	}
	// *-----------------------------------------------------------
	const billingHeaders = {
		columns: [
			{
				text: 'Från',
				style: 'documentBillingTitle',
			},
			{
				text: 'Till',
				style: 'documentBillingTitle',
			},
		],
	}
	const billingDetails = {
		columns: [
			{
				text: documentOurCompany.company_name,
				style: 'documentBillingCompanyDetails',
			},
			{
				text: '', //data.clientCompany
				style: 'documentBillingCompanyDetails',
			},
		],
	}
	const references = {
		columns: [
			{
				text: 'Vår Ref: ' + documentOurCompany.company_reference,
				style: 'documentBillingDetails',
			},
			{
				text: '', // data.toReference,
				style: 'documentBillingDetails',
			},
		],
	}
	const nicknames = {
		columns: [
			{
				text: '',
				style: 'documentBillingDetails',
			},
			{
				text: '', // data.clientNickName
				style: 'documentBillingDetails',
			},
		],
	}
	const addressTitle = {
		columns: [
			{
				text: 'Adress',
				style: 'documentBillingAddressTitle',
			},
			{
				text: '', // data.addressLabel
				style: 'documentBillingAddressTitle',
			},
		],
	}
	const billingAddress = {
		columns: [
			{
				text:
					documentOurCompany.company_address_line_1 +
					' ' +
					documentOurCompany.company_address_line_2 +
					' \n ' +
					documentOurCompany.company_post_code +
					' ' +
					documentOurCompany.company_city +
					' \n ' +
					documentOurCompany.company_state +
					' ' +
					documentOurCompany.company_country +
					' \n ',
				style: 'documentBillingAddress',
			},
			{
				text: '' + ' \n ' + '' + ' \n ' + '' + ' \n ',
				style: 'documentBillingAddress',
			},
		],
	}
	const orgNumber = {
		columns: [
			{
				text: 'Org Nummer: ' + documentOurCompany.company_org_num,
				style: 'organizationNumber',
			},
			{
				text: '',
				style: 'organizationNumber',
			},
		],
	}
	const horizontalLine = {
		table: {
			widths: ['*'],
			body: [[' '], [' ']],
		},
		layout: 'lightHorizontalLines',
	}
	const aboutUs = {
		image: data.aboutUsImage64,
		width: 500,
	}
	// *************** Push Stock Group  ***************************
	const pushStockGroups = (item) => {
		docDefinition.content.push({
			text: `Stock Grupp: ${item.stock_groups}`,
			style: 'stockGroup',
		})
	}
	// * create page break
	const pushBreak = () => {
		docDefinition.content.push([
			{
				text: '',
				fontSize: 14,
				bold: true,
				pageBreak: 'after',
				margin: [0, 0, 0, 8],
			},
		])
	}
	// * Create rowsItems array (tables depends on dispatches)
	let rowsItems = []
	// * Push table headers for each dispatch
	const pushRowsItemsHeaders = () => {
		rowsItems.push([
			{
				text: data.stockNumberLabel,
				style: ['itemsHeader', 'center'],
			},
			{
				text: data.nameLabel,
				style: ['itemsHeader', 'center'],
			},
			{
				text: data.unitLabel,
				style: ['itemsHeader', 'center'],
			},
			{
				text: data.priceLabel,
				style: ['itemsHeader', 'center'],
			},
		])
	}
	// * Push dispatch lines for each dispatch
	const pushRowsItemsLines = (stockItemLines) => {
		for (let item of stockItemLines) {
			rowsItems.push([
				{
					text: item.stock_id,
					style: ['itemsHeader', 'center'],
				},
				{
					text: item.stock_name,
					style: 'itemsHeader',
				},
				{
					text: item.stock_unit,
					style: ['itemsHeader', 'center'],
				},
				{
					text: getItemListPrice(item),
					style: ['itemsHeader', 'alignRight'],
				},
			])
		}
	}
	// * Push dispatch tables to doc definition // ****** uncomment
	const pushStockItemTables = () => {
		docDefinition.content.push({
			table: {
				// headers are automatically repeated if the table spans over multiple pages
				// you can declare how many rows should be treated as headers
				headerRows: 1,
				widths: [90, 270, 50, 75],
				style: 'tableHeader',
				body: rowsItems,
			},
		})
	}
	const createTablesDependsOnStockGroups = () => {
		console.log(
			'=============> relatedStockGroupsArray',
			relatedStockGroupsArray,
		)
		for (let item of relatedStockGroupsArray) {
			rowsItems = []
			let stockItemLines = []
			// console.log('dispatch number', item)
			stockItemLines = documentData.filter(
				(line) => line.stock_group === item.stock_groups,
			)
			console.log('***stockItemLines', stockItemLines)
			console.log('***rowsItems', rowsItems)
			pushRowsItemsHeaders()
			pushRowsItemsLines(stockItemLines)
			pushStockGroups(item)
			pushStockItemTables()
			pushBreak()
		}
	}
	const invoiceNotesLabel = {
		text: data.notesLabel,
		style: 'notesTitle',
	}
	const invoiceNotesLines = {
		text: ` ${data.notesLine3} - ${data.notesLine1}
      ${data.notesLine2}`,
		style: 'notesText',
	}
	docDefinition.content.push(header)
	docDefinition.content.push(billingHeaders)
	docDefinition.content.push(billingDetails)
	docDefinition.content.push(references)
	docDefinition.content.push(nicknames)
	docDefinition.content.push(addressTitle)
	docDefinition.content.push(billingAddress)
	docDefinition.content.push(orgNumber)
	docDefinition.content.push(horizontalLine)
	docDefinition.content.push(invoiceNotesLabel)
	docDefinition.content.push(invoiceNotesLines)
	if (documentOurCompany.company_nickname === 'alfe') {
		docDefinition.content.push(aboutUs)
	}
	createTablesDependsOnStockGroups()
	// docDefinition.content.push(invoiceNotesLabel)
	// docDefinition.content.push(invoiceNotesLines)
	// ********************** PUSH CONTENT END********************************************************

	return docDefinition
}

export default function usePriceListReportData() {
	return {
		getPriceListReportData,
	}
}
